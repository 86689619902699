import React from 'react';
import { Link } from 'react-router-dom';
import LatestEntries from '../latest-entries/latest-entries';
import TodaysEntries from '../todays-entries/todays-entries';

import './header.css';

export const Header = () => {
  return (
    <header className="training-log-header">
      <nav>
        <Link to="/">
          <h1>Training Log</h1>
        </Link>
        <ul>
          <li><Link to="/add-entry">Add Entry</Link></li>
          <li><Link to="/manage-types">Manage Types</Link></li>
          <li><Link to="/list-entries">List Entries</Link>
            <ul>
              <li><Link to="/list-entries/today">Today</Link></li>
              <li><Link to="/list-entries/week">last 7 days</Link></li>
              <li><Link to="/list-entries/month">last 30 days</Link></li>
            </ul>
          </li>
          <li>
            <Link to="/list-summary">List Summary</Link>
            <ul>
              <li><Link to="/list-summary/today">Today</Link></li>
              <li><Link to="/list-summary/week">last 7 days</Link></li>
              <li><Link to="/list-summary/month">last 30 days</Link></li>
            </ul>
          </li>
        </ul>
      </nav>

      <LatestEntries />

      <TodaysEntries />
    </header>
  );
};

export default Header;