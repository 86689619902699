import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useLocation } from 'react-router-dom';
import { fetchEntriesDateRange } from '../queries/queries';
import { subDays, subMonths } from 'date-fns';
import EntryList from '../components/entry-list/entry-list';

import './list-entries.css';

const ListEntries = () => {
  const location = useLocation();

  const { startDate, endDate } = useMemo(() => {
    const sevenDaysAgo = subDays(new Date(), 6);
    const thirtyDaysAgo = subDays(new Date(), 29);

    const formatDate = (date) => date.toISOString().slice(0, 10);

    const today = formatDate(new Date());
    const sixMonthsAgo = subMonths(today, 6);

    switch (location.pathname) {
      case '/list-entries/all':
        return { startDate: sixMonthsAgo , endDate: today };
      case '/list-entries/today':
        return { startDate: today, endDate: today };
      case '/list-entries/week':
        return { startDate: formatDate(sevenDaysAgo), endDate: today };
      case '/list-entries/month':
        return { startDate: formatDate(thirtyDaysAgo), endDate: today };
      default:
        return { startDate: '1970-01-01', endDate: today }; // Fetch all by default
    }
  }, [location.pathname]);

  // Use react-query to fetch entries
  const {
    data: entries,
    isLoading: isEntriesLoading,
    error: entriesError,
  } = useQuery(
    ['entries', startDate, endDate],
    fetchEntriesDateRange
  );

  if (entriesError) {
    return <p>Error fetching entries: {entriesError.message}</p>;
  }

  return (
    <div className="list-entries">
      <Routes>
        <Route path="/" element={<></>} />
        <Route path="today" element={<h3>Entries for Today</h3>} />
        <Route path="week" element={<h3>Entries for last 7 days</h3>} />
        <Route path="month" element={<h3>Entries for last 30 days</h3>} />
      </Routes>

      <h2>Training Entries</h2>
      <EntryList entries={entries} isLoading={isEntriesLoading} />
    </div>
  );
};

export default ListEntries;
