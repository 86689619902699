// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-banner {
  background-color: white;
  display: flex;
  justify-content: end;
  gap: .5rem;
  overflow: hidden;
  padding: .5rem;
}

.summary-banner-day {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 65%;
  min-width: 30px;
  height: 24px;
  border: 1px solid #e0e0e0;
  color: #999;
  border-radius: 4px;

  &:last-child {
    outline: 1px solid rgb(247, 178, 28);
    outline-offset: 2px;
  }

  &.summary-banner-day--has-training {
    border: 1px solid #333;
    color: #333;
  }

  &.training-type-id__43 {
    background-color: rgba(11, 109, 246, 0.6);
    color: white;
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/summary-banner/summary-banner.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,oBAAoB;EACpB,UAAU;EACV,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,kBAAkB;;EAElB;IACE,oCAAoC;IACpC,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,yCAAyC;IACzC,YAAY;EACd;AACF","sourcesContent":[".summary-banner {\n  background-color: white;\n  display: flex;\n  justify-content: end;\n  gap: .5rem;\n  overflow: hidden;\n  padding: .5rem;\n}\n\n.summary-banner-day {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n  font-size: 65%;\n  min-width: 30px;\n  height: 24px;\n  border: 1px solid #e0e0e0;\n  color: #999;\n  border-radius: 4px;\n\n  &:last-child {\n    outline: 1px solid rgb(247, 178, 28);\n    outline-offset: 2px;\n  }\n\n  &.summary-banner-day--has-training {\n    border: 1px solid #333;\n    color: #333;\n  }\n\n  &.training-type-id__43 {\n    background-color: rgba(11, 109, 246, 0.6);\n    color: white;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
