import { useQuery } from 'react-query';
import { fetchLatestEntries } from '../queries/queries';

export const useLatestEntries = ({ onSuccess } = {}) => useQuery(
  ['latestEntries', onSuccess],
  fetchLatestEntries,
  {
    onSuccess: typeof onSuccess === 'function' ? onSuccess : () => {}
  }
)
