import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { fetchCategories, fetchTrainingTypes } from '../queries/queries';
import axios from 'axios';

import './manage-types.css';

const apiHost = process.env.REACT_APP_API_HOST;


const ManageTypes = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [newTrainingType, setNewTrainingType] = useState('');

  const queryClient = useQueryClient();

  const {
    data: categories = [],
    isLoading: isCategoriesLoading,
    error: categoriesError,
    // refetch: fetchCategories
  } = useQuery(
    ['categories'],
    fetchCategories
  );

  const {
    data: trainingTypes = [],
    isLoading: isTrainingTypesLoading,
    // error: trainingTypesError,
  } = useQuery(
    ['trainingTypes', selectedCategoryId],
    fetchTrainingTypes,
    {
      enabled: !!selectedCategoryId
    }
  );

  const addCategoryMutation = useMutation(
    (newCategory) => axios.post(`${apiHost}/categories`, { categoryName: newCategory }),
    {
      onSuccess: () => {
        console.log('Category added!');
        queryClient.invalidateQueries('categories');
        setNewCategory('');
      }
    }
  );

  const addTrainingTypeMutation = useMutation(
    (newTrainingType) => axios.post(`${apiHost}/types`, {
      trainingType: newTrainingType,
      trainingCategoryId: selectedCategoryId
    }),
    {
      onSuccess: () => {
        console.log('Training Type added!');
        queryClient.invalidateQueries('trainingTypes');
        setNewTrainingType('');
      }
    }
  );

  const deleteCategoryMutation = useMutation(
    (categoryId) => axios.delete(`${apiHost}/categories/${categoryId}`),
    {
      onSuccess: () => {
        console.log('Category deleted!');
        queryClient.invalidateQueries('categories');
        // queryClient.invalidateQueries('trainingTypes');
        setSelectedCategoryId('');
        setNewCategory('');
      }
    }
  );

  const deleteTrainingTypeMutation = useMutation(
    (typeId) => axios.delete(`${apiHost}/types/${typeId}`),
    {
      onSuccess: () => {
        console.log('Training Type deleted!');
        // Refetch training types after deletion
        queryClient.invalidateQueries(['trainingTypes', selectedCategoryId]);
        // setNewTrainingType('');
      }
    }
  );

  const handleAddCategory = () => {
    if (newCategory) {
      addCategoryMutation.mutate(newCategory);
    }
  };

  const handleAddTrainingType = () => {
    if (newTrainingType && selectedCategoryId) {
      addTrainingTypeMutation.mutate(newTrainingType);
    }
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  const handleDeleteCategory = (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category and all its associated training types?')) {
      deleteCategoryMutation.mutate(categoryId);
    }
  };

  const handleDeleteTrainingType = (typeId) => {
    if (window.confirm('Are you sure you want to delete this training type?')) {
      deleteTrainingTypeMutation.mutate(typeId);
    }
  };

  if (isCategoriesLoading) {
    return <p>Loading categories...</p>;
  }

  if (categoriesError) {
    return <p>Error loading categories: {categoriesError.message}</p>;
  }

  return (
    <div>
      <h2>Manage Training Categories & Types</h2>

      {/* Category Management */}
      <div>
        <h3>Categories</h3>
        <ul>
          {categories.map(category => (
            <li key={category.id}>
              <button className={category.id === selectedCategoryId ? 'category-btn category-btn--selected' : 'category-btn'} onClick={() => handleCategorySelect(category.id)}>
                {category.categoryName}
              </button>
              <button onClick={() => handleDeleteCategory(category.id)}>Delete Category</button>
            </li>
          ))}
        </ul>
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="New Category"
        />
        <button onClick={handleAddCategory}>Add Category</button>
      </div>

      {/* Training Type Management */}
      <div>
        <h3>Training Types for Selected Category</h3>
        <ul>
          {trainingTypes.length === 0 && <p>No training types for this category.</p>}
          {trainingTypes.map(type => (
            <li key={type.id}>
              {type.trainingType}
              <button onClick={() => handleDeleteTrainingType(type.id)}>Delete Type</button>
            </li>
          ))}
        </ul>

        {/* Add new training type */}
        <div>
          <input
            type="text"
            value={newTrainingType}
            onChange={(e) => setNewTrainingType(e.target.value)}
            placeholder="New Training Type"
          />
          <button onClick={handleAddTrainingType}>Add Training Type</button>
        </div>
      </div>
    </div>
  );
}

export default ManageTypes;