import React from 'react'
import { capitalizeString } from '../../utils/string-utils'
import { getDateRelation } from '../../utils/date-utils'

import './entry-list.css'

const groupEntries = (entries) => {
  const groupedEntries = {};
  entries.forEach(entry => {
      const date = entry.date.slice(0, 10);
      if (!groupedEntries[date]) {
        groupedEntries[date] = [];
      }
      groupedEntries[date].push(entry);
    });

  return groupedEntries;
}

const EntryList = ({ entries = [], isLoading }) => {
  const groupedEntries = groupEntries(entries);

  return (
    <div className="entry-list">
      <ul>
        {isLoading && <p>Loading entries...</p>}

        {!isLoading && entries.length === 0 && <p>No entries found for this period.</p>}

        {Object.keys(groupedEntries).map(date => {
          const entryDate = date.slice(0, 10);
          return (
            <li key={entryDate}>
              <h3>{getDateRelation(entryDate)} - {entryDate}</h3>
              <ul>
                {groupedEntries[date].map(entry => {
                  return (
                    <li key={entry.date + '_' + entry.trainingType}>
                      {`${capitalizeString(entry.trainingType)} - ${entry.totalRepetitions} reps`}
                    </li>
                  )
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default EntryList