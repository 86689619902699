// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  flex-direction: column;
  justify-content: center;  
  box-sizing: border-box;
  margin-inline: 24px;
  
  width: calc(100svw - 48px); /* Bredden på viewport utan scrollbar */
  /* overflow-y: scroll; */

  ul {
    padding-left: 24px;
  }
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;;EAEnB,0BAA0B,EAAE,uCAAuC;EACnE,wBAAwB;;EAExB;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".App {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;  \n  box-sizing: border-box;\n  margin-inline: 24px;\n  \n  width: calc(100svw - 48px); /* Bredden på viewport utan scrollbar */\n  /* overflow-y: scroll; */\n\n  ul {\n    padding-left: 24px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
