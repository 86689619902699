import { useQuery } from 'react-query';
import { fetchEntriesDateRange } from '../queries/queries';

export const useTodaysEntries = () => {
  const today = new Date().toISOString().slice(0, 10);

  return useQuery(
    ['fetchEntriesDateRange', today, today],
    fetchEntriesDateRange
  );
};
