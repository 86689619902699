// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entry-list {
  width: fit-content;
}

.entry-list li.new-date {
  margin-top: 12px;
  padding-top: 6px;
  position: relative;
  border-top: 2px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/components/entry-list/entry-list.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,0BAA0B;AAC5B","sourcesContent":[".entry-list {\n  width: fit-content;\n}\n\n.entry-list li.new-date {\n  margin-top: 12px;\n  padding-top: 6px;\n  position: relative;\n  border-top: 2px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
