import axios from 'axios';

const apiHost = process.env.REACT_APP_API_HOST;

const fetchCategories = async () => {
  const response = await axios.get(`${apiHost}/categories`);
  return response.data.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
}

const fetchTrainingTypes = async ({ queryKey }) => {
  const [_key, categoryId] = queryKey;
  if (categoryId) {
    const response = await axios.get(`${apiHost}/types`, {
      params: {
        categoryId
      }
    });
    return response.data.sort((a, b) => a.trainingType.localeCompare(b.trainingType));
  }
  return [];
}

const fetchEntriesDateRange = async ({ queryKey = {} }) => {
  const [_key, startDate, endDate] = queryKey;
  const today = new Date().toISOString().slice(0, 10);
  const response = await axios.get(`${apiHost}/entries/date-range`, {
    params: {
      startDate: startDate || today,
      endDate: endDate || today,
    },
  });
  return response.data;
}

const fetchEntriesDateRangeSummary = async ({ queryKey = {} }) => {
  const [, startDate, endDate] = queryKey;
  const today = new Date().toISOString().slice(0, 10);
  const response = await axios.get(`${apiHost}/entries/date-range/summary`, {
    params: {
      startDate: startDate || today,
      endDate: endDate || today,
    },
  });
  return response.data;
}

const fetchLastEntry = async () => {
  const response = await axios.get(`${apiHost}/entries/latest`);
  return response.data;
}

const fetchLatestEntries = async () => {
  const response = await axios.get(`${apiHost}/entries/latest/summary`);
  return response.data;
}

export { 
  fetchCategories, 
  fetchEntriesDateRange,
  fetchEntriesDateRangeSummary,
  fetchLastEntry,
  fetchLatestEntries,
  fetchTrainingTypes
};