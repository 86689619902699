import { useQuery } from 'react-query';
import { fetchLastEntry } from '../queries/queries';

export const useLastEntry = ({ onSuccess } = {}) => useQuery(
  ['lastEntry', onSuccess],
  fetchLastEntry,
  {
    onSuccess: typeof onSuccess === 'function' ? onSuccess : () => {}
  }
)
