const getDateRelation = (inputDate) => {
  const today = new Date();
  const date = new Date(inputDate);

  // Reset hours for date comparison
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  const diffTimeInMs = date - today;
  const diffDays = Math.floor(diffTimeInMs / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return "idag";
  } else if (diffDays === 1) {
    return "imorgon";
  } else if (diffDays === -1) {
    return "igår";
  } else if (diffDays > 0) {
    return `${diffDays} dagar`;
  } else {
    return `${Math.abs(diffDays)} dagar sedan`;
  }
}

export { getDateRelation };