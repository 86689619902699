import axios from 'axios';
import { subDays } from 'date-fns';

const apiHost = process.env.REACT_APP_API_HOST;

const fetchCategories = async () => {
  const response = await axios.get(`${apiHost}/categories`);
  return response.data.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
}

const fetchTrainingTypes = async ({ queryKey }) => {
  const [_key, categoryId] = queryKey;
  if (categoryId) {
    const response = await axios.get(`${apiHost}/types`, {
      params: {
        categoryId
      }
    });
    return response.data.sort((a, b) => a.trainingType.localeCompare(b.trainingType));
  }
  return [];
}

const fetchEntriesDateRange = async ({ queryKey = {} }) => {
  const [_key, startDate, endDate] = queryKey;
  const today = new Date().toISOString().slice(0, 10);
  const response = await axios.get(`${apiHost}/entries/date-range`, {
    params: {
      startDate: startDate || today,
      endDate: endDate || today,
    },
  });
  return response.data;
}

const fetchEntriesDateRangeSummary = async ({ queryKey = {} }) => {
  const [, startDate, endDate] = queryKey;
  const today = new Date().toISOString().slice(0, 10);
  const response = await axios.get(`${apiHost}/entries/date-range/summary`, {
    params: {
      startDate: startDate || today,
      endDate: endDate || today,
    },
  });
  return response.data;
}

const fetchEntriesDateRangeLast30Days = async ({ queryKey = {} }) => {
  const [, startDate, endDate] = queryKey;
  const today = new Date().toISOString().slice(0, 10);
  const last30Days = subDays(new Date(), 30).toISOString().slice(0, 10);
  const response = await axios.get(`${apiHost}/entries/date-range`, {
    params: {
      startDate: startDate || last30Days,
      endDate:  endDate || today,
    },
  });
  return response.data;
}

const fetchEntriesDateRangeSummaryLast30Days = async () => {
  const today = new Date();
  const last30Days = new Date(today);
  last30Days.setDate(today.getDate() - 30);
  const response = await axios.get(`${apiHost}/entries/date-range/summary`, {
    params: {
      startDate: last30Days.toISOString().slice(0, 10),
      endDate: today.toISOString().slice(0, 10),
    },
  });
  return response.data;
}

const fetchLastEntry = async () => {
  const response = await axios.get(`${apiHost}/entries/latest`);
  return response.data;
}

const fetchLatestEntries = async () => {
  const response = await axios.get(`${apiHost}/entries/latest/summary`);
  return response.data;
}

export {
  fetchCategories,
  fetchEntriesDateRange,
  fetchEntriesDateRangeLast30Days,
  fetchEntriesDateRangeSummary,
  fetchEntriesDateRangeSummaryLast30Days,
  fetchLastEntry,
  fetchLatestEntries,
  fetchTrainingTypes
};