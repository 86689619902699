// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.training-log-header {
  padding: 12px;
  background-color: gold;
}

@media screen and (min-width: 768px) {
  .training-log-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    & > *:not(:first-child) {
      padding-inline: 12px;
      border-left: 1px dashed #999;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,aAAa;IACb,kCAAkC;;IAElC;MACE,oBAAoB;MACpB,4BAA4B;IAC9B;EACF;AACF","sourcesContent":[".training-log-header {\n  padding: 12px;\n  background-color: gold;\n}\n\n@media screen and (min-width: 768px) {\n  .training-log-header {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n\n    & > *:not(:first-child) {\n      padding-inline: 12px;\n      border-left: 1px dashed #999;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
