import { useTodaysEntries } from '../../hooks/use-todays-entries';

const TodaysEntries = () => {
  const {
    data: todaysEntries,
    isLoading,
    error
  } = useTodaysEntries();

  return (
    <div>
      <h2>Idag:</h2>

      {isLoading && <p>Loading today's entries...</p>}

      {error && <p>Error loading today's entries: {error.message}</p>}

      {todaysEntries?.length > 0 && (
        <ul>
          {todaysEntries.map(({ trainingTypeId, trainingType, totalRepetitions }) => (
            <li key={trainingTypeId}>
              {`${trainingType} (${totalRepetitions})`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TodaysEntries;