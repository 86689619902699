import React from 'react'
import { useQuery } from 'react-query'
import { clsx } from 'clsx'
import { fetchEntriesDateRangeLast30Days } from '../../queries/queries';

import './summary-banner.css'

// Get the last 30 days as an array of date strings
const getLast30Days = () => {
  const dates = [];
  const today = new Date();
  for (let i = 29; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    dates.push(date.toISOString().split("T")[0]); // Format: YYYY-MM-DD
  }
  return dates;
};

const SummaryBanner = () => {
  // Use react-query to fetch entries
  const {
    data: latestEntries,
    isLoading: isEntriesLoading,
    error: entriesError,
  } = useQuery(
    ['entries'],
    fetchEntriesDateRangeLast30Days
  );

  const last30Days = getLast30Days();


  const trainingMap = new Map();

  if (isEntriesLoading) {
    return <p>Loading latest entries...</p>;
  }

    latestEntries.forEach(({ date, trainingTypeId }) => {
      const formattedDate = date.split(" ")[0]; // Extract YYYY-MM-DD
      if (!trainingMap.has(formattedDate)) {
        trainingMap.set(formattedDate, []);
      }

      trainingMap.get(formattedDate).push(trainingTypeId);
    });

  return (
    <div className="summary-banner">
      {last30Days.map((dateStr) => {
        const day = new Date(dateStr).getDate();
        const month = new Date(dateStr).getMonth() + 1;

        const trainingIds = trainingMap.get(dateStr) || [];
        const trainingClassNames = trainingIds.map(id => `training-type-id__${id}`);

        return (
          <div
            key={dateStr}
            className={clsx(
              'summary-banner-day', 
              trainingClassNames,
              {
                'summary-banner-day--has-training': trainingIds.length > 0,
              }
            )}
          >
            {day}/{month}
          </div>
        );
      })}

    </div>
  )
}

export default SummaryBanner