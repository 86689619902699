import { useLatestEntries } from '../../hooks';
import { getDateRelation } from '../../utils/date-utils';

const LatestEntries = () => {
  const {
    data: latestEntries,
    isLoading,
    error
  } = useLatestEntries();

  const latestDate = latestEntries?.latestDate ? getDateRelation(new Date(latestEntries.latestDate)) : '';

  return (
    <div>
      <h2>Tidigare: {latestDate}</h2>
      {isLoading && <p>Loading latest entries...</p>}

      {error && <p>Error loading latest entries: {error.message}</p>}

      {latestEntries?.groupedEntries && (
      <ul>
        {latestEntries.groupedEntries.map(({ trainingTypeId, trainingType, totalRepetitions }) => (
          <li key={trainingTypeId}>
            {`${trainingType} (${totalRepetitions})`}
          </li>
        ))}
      </ul>
      )}
    </div>
  );
}

export default LatestEntries;