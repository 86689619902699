import React, { useState } from 'react';
import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { fetchCategories, fetchTrainingTypes, fetchEntriesDateRange, fetchLatestEntry } from '../queries/queries';
import EntryList from '../components/entry-list/entry-list';

import './add-entry.css';

const TrainingTypeButton = ({ id, className, repsToday, isChecked, onChange, label }) => {
  const classNames = ['button-wrapper'];
  if (repsToday > 0) {
    classNames.push('has-reps');
  }

  return (
    <div key={id} className={classNames.join(' ')}>
      <input
        type="radio"
        id={`type-${id}`}
        name="trainingType"
        value={id}
        checked={isChecked}
        onChange={onChange}
      />
      <label htmlFor={`type-${id}`}>{label}</label>
      {repsToday > 0 && <div className="reps-today">{repsToday}</div>}
    </div>
  )
}

const AddEntry = () => {
  const [date, setDate] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(''); // Store selected category
  const [trainingTypeId, setTrainingTypeId] = useState('');
  const [repetitions, setRepetitions] = useState('');

  const apiHost = process.env.REACT_APP_API_HOST;
  const queryClient = useQueryClient();

  const getRepsToday = (trainingTypeId) => {
    const todaysEntry = todaysEntries.find(entry => entry.trainingTypeId === trainingTypeId);
    return todaysEntry ? todaysEntry.totalRepetitions : 0;
  }

  // Fetch categories
  const {
    data: categories,
    isLoading: isCategoriesLoading,
    error: categoriesError
  } = useQuery(
    ['categories'],
    () => fetchCategories()
  );

  // Fetch training types based on selected category
  const {
    data: trainingTypes,
    isLoading: isTrainingTypesLoading,
    // error: trainingTypesError
  } = useQuery(
    ['trainingTypes', selectedCategoryId],
    fetchTrainingTypes,
    {
      enabled: !!selectedCategoryId
    }
  );

  // Fetch today's entries
  const {
    data: todaysEntries,
    isLoading: isTodaysEntriesLoading,
    // error: todaysEntriesError
  } = useQuery(
    ['fetchEntriesDateRange', new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
    fetchEntriesDateRange
  )

  // Fetch the latest entry for setting the default training type
  useQuery(
    ['latestEntry'],
    () => fetchLatestEntry(),
    {
      onSuccess: (latestEntry) => {
        if (latestEntry) {
          setTrainingTypeId(latestEntry.trainingTypeId);
        }
        setDate(new Date().toISOString().slice(0, 16));
      }
    }
  )

  // Mutation for submitting a new entry
  const addEntryMutation = useMutation(
    (newEntry) => axios.post(`${apiHost}/entries`, newEntry),
    {
      onSuccess: () => {
        console.debug('Entry added successfully!');
        setDate(new Date().toISOString().slice(0, 16));
        setTrainingTypeId('');
        setRepetitions('');

        // Invalidate queries to refetch todays entries
        queryClient.invalidateQueries(['fetchEntriesDateRange']);
      },
      onError: () => {
        alert('Failed to add entry.');
      }
    }
  );

  const handleSubmit = (e, value) => {
    e?.preventDefault();
    const newEntry = {
      date,
      trainingTypeId: parseInt(trainingTypeId),
      repetitions: parseInt(value || repetitions)
    };
    addEntryMutation.mutate(newEntry);
  };

  // Quick repetitions button click handler
  const handleQuickRepetitionClick = (value) => {
    // setRepetitions(value);
    handleSubmit(null, value); // Automatically submit after setting repetitions
  };

  const handleCategoryChange = (e, categoryId) => {
    e.preventDefault();
    setSelectedCategoryId(categoryId)
    setTrainingTypeId(''); // Reset training type on category
  }

  if (isCategoriesLoading) return <p>Loading categories...</p>;

  if (categoriesError) return <p>Error loading categories: {categoriesError.message}</p>;

  return (
    <div className="add-entry">
      <h2>Add Training Entry</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Date:</label>
          <input
            type="datetime-local"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <div className="category-group">
            <label>Category:</label>
            <div className="button-group">
              {categories.map(category => (
                <div key={category.id} className="button-wrapper">
                  <input
                    type="radio"
                    id={`category-${category.id}`}
                    name="category"
                    value={category.id}
                    checked={selectedCategoryId === category.id}
                    onChange={(e) => handleCategoryChange(e, category.id)}
                  />
                  <label htmlFor={`category-${category.id}`}>{category.categoryName}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="type-group">
            <label>Training Type:</label>
            {isTrainingTypesLoading && <p>Loading training types...</p>}
            {selectedCategoryId && !isTrainingTypesLoading && (
              <div className="button-group">
                {trainingTypes.map(type => {
                  const repsToday = getRepsToday(type.id);
                  return (
                    <TrainingTypeButton
                      id={type.id}
                      repsToday={repsToday}
                      isChecked={trainingTypeId === type.id}
                      onChange={() => setTrainingTypeId(type.id)}
                      label={type.trainingType}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <div className="repetition-group">
            <label>Quick selections:</label>
            {selectedCategoryId && trainingTypeId && (
              <>
                <div className="quick-reps">
                  {/* Quick selection buttons */}
                  {[10, 15, 20, 25, 30, 45, 60, 90].map(value => (
                    <button
                      type="button"
                      key={value}
                      onClick={() => handleQuickRepetitionClick(value)}
                      className="quick-reps-btn"
                    >
                      {value}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="submit-group">
            <label>Repetitions:</label>
            <input
              type="number"
              value={repetitions}
              onChange={(e) => setRepetitions(e.target.value)}
              required
            />
            <button type="submit">Add Entry</button>
          </div>
        </div>
      </form>

      <h2>Today's Entries</h2>
      <EntryList entries={todaysEntries} isLoading={isTodaysEntriesLoading} />
    </div>
  );
};

export default AddEntry;
