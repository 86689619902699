import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AddEntry from './routes/add-entry';
import ListEntries from './routes/list-entries';
import ManageTypes from './routes/manage-types';
import ListSummary from './routes/list-summary';
import Home from './routes/home';
import Header from './components/header/header';
import SummaryBanner from './components/summary-banner/summary-banner';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <SummaryBanner />
        <main>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/add-entry" element={<AddEntry />} />
            <Route path="/list-summary/*" element={<ListSummary />} />
            <Route path="/list-entries/*" element={<ListEntries />} />
            <Route path="/manage-types" element={<ManageTypes />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
